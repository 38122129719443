/* eslint-disable jsx-a11y/no-autofocus */
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import React, {
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import isEqual from "react-fast-compare";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { ThemeProvider } from "styled-components";

import Button from "components/Button";
import Icon from "components/Icon";
import LanguagePicker from "components/LanguagePicker";
import Navigation from "components/Navigation";
import NavigationMega from "components/NavigationMega/NavigationMega";
import SearchOverlay from "components/SearchOverlay";
import { useBrand, useBreakpoint, useEventListener } from "hooks";
import useOnClickOutside from "hooks/useOnClickOutside";
import usePageContent from "hooks/usePageContent";
import {
    SHOW_HEADER,
    HIDE_HEADER,
    SHOW_SEARCH,
    HIDE_SEARCH,
} from "store/actionTypes";
import getTestId from "utils/getTestId";
import useScrollDirection, {
    useScrollPosition,
} from "utils/useScrollDIrection";

import {
    Wrapper,
    Container,
    Inner,
    LogoLink,
    SearchButton,
    MenuButton,
    SkipLink,
    SkipLinkWrapper,
} from "./Header.styled";
import HeaderProps, { HeaderLogoProps } from "./HeaderProps";

const Logo = React.memo(
    ({ startPageUrl, logoTitle, logoIcon }: HeaderLogoProps): ReactElement => (
        <LogoLink
            to={startPageUrl}
            title={logoTitle}
            ariaLabel={logoTitle}
            gtmClickType="home"
            data-cy={getTestId("Logo")}
        >
            <Icon key={`Logo-${logoIcon}`} icon={logoIcon} />
        </LogoLink>
    ),
);
Logo.displayName = "Logo";

const Header = ({
    navigation,
    mainLinks,
    logoTitle,
    startPageUrl,
    searchPageUrl,
    loginPageText,
    loginPageTitle,
    loginPageUrl,
    l18n,
    sites,
    visible = true,
    searchOverlayVisible,
}: HeaderProps): ReactElement => {
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();

    const { theme, contentTheme } = usePageContent();
    const location = useLocation();
    const scrollDirection = useScrollDirection();
    const scrollPosition = useScrollPosition();
    const [activeNav, setActiveNav] = useState<string | undefined>();
    const [headerPinned, setHeaderPinned] = useState<boolean>(true);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [searchVisible, setSearchVisible] = useState<boolean>(false);
    const [headerActive, setHeaderActive] = useState<boolean>(false);
    const [mouseOver, setMouseOver] = useState<boolean>(false);
    const sendDataToGTM = useGTMDispatch();
    const breakpoint = useBreakpoint();
    const mobileMenuNavName = "hamburger"; // 🍔🤷
    const isSearchPage = searchPageUrl === location.pathname;

    const openHandler = useCallback(
        (navText?: string): void => {
            setMenuOpen(true);
            setSearchVisible(false);
            setHeaderActive(true);
            sendDataToGTM({
                event: "open_menu",
                click_text: navText,
            });
        },
        [sendDataToGTM],
    );

    const closeHandler = useCallback((): void => {
        setActiveNav(undefined);
        setMenuOpen(false);
    }, []);

    const toggleMenu = useCallback(
        (navText?: string): void => {
            if (menuOpen) {
                closeHandler();
            } else {
                openHandler(navText);
            }
        },
        [closeHandler, menuOpen, openHandler],
    );

    const toggleNavHandler = useCallback(
        (nav: string): void => {
            if (activeNav !== nav) {
                setActiveNav(nav);
            } else {
                setActiveNav(undefined);
            }
        },
        [activeNav],
    );

    const setActiveNavHandler = useCallback(
        (nav: string, navText?: string): void => {
            if (activeNav !== nav) {
                setActiveNav(nav);
                openHandler(navText);
            } else {
                closeHandler();
            }
        },
        [activeNav, closeHandler, openHandler],
    );

    const enterHandler = (): void => {
        setMouseOver(true);
        setHeaderActive(true);
    };

    const leaveHandler = (): void => {
        setMouseOver(false);
        if (!menuOpen && !searchVisible) setHeaderActive(false);
    };

    const handleMenuBtnKeyDown = useCallback(
        (
            event: React.KeyboardEvent<HTMLButtonElement>,
            navText?: string,
        ): void => {
            if (event?.key === "Enter") {
                event.preventDefault();
                toggleMenu(navText);
            }
        },
        [toggleMenu],
    );

    const navigateHandler = useCallback(
        (level: number, text?: string): void => {
            sendDataToGTM({
                event: "menu_click",
                menu_level: level,
                click_text: text,
            });
        },
        [sendDataToGTM],
    );

    const handleClickOutside = useCallback((): void => {
        if (menuOpen) {
            closeHandler();
        }
    }, [closeHandler, menuOpen]);

    const toggleSearch = useCallback((): void => {
        if (isSearchPage) {
            const elem = document.getElementById("searchfield");
            if (elem)
                elem.scrollIntoView({ block: "center", inline: "nearest" });
        } else if (searchVisible) {
            setSearchVisible(false);
            setHeaderActive(false);
        } else {
            setSearchVisible(true);
            setHeaderActive(true);
        }
    }, [isSearchPage, searchVisible]);

    useEffect(() => {
        closeHandler();
    }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSearchVisible(searchOverlayVisible);
    }, [searchOverlayVisible]);

    useEffect((): void => {
        if (!headerPinned && !mouseOver && !menuOpen)
            dispatch({ type: HIDE_HEADER });
        else dispatch({ type: SHOW_HEADER });
    }, [headerPinned, mouseOver, menuOpen, dispatch]);

    useEffect((): void => {
        if (!headerPinned && !mouseOver && !menuOpen && !searchVisible)
            dispatch({ type: HIDE_HEADER });
        else dispatch({ type: SHOW_HEADER });
    }, [mouseOver, menuOpen, searchVisible, dispatch, headerPinned]);

    useEffect((): void => {
        scrollPosition > 5 && scrollDirection === "down"
            ? setHeaderPinned(false)
            : setHeaderPinned(true);
        scrollPosition > 50 || mouseOver || menuOpen || searchVisible
            ? setHeaderActive(true)
            : setHeaderActive(false);
    }, [scrollPosition, scrollDirection, mouseOver, menuOpen, searchVisible]);

    useEventListener("keydown", (event: KeyboardEvent) => {
        // Close nav and search on ESC
        if (menuOpen && event.key === "Escape") {
            setSearchVisible(false);
            setHeaderActive(false);
            closeHandler();
        }
    });

    useEffect((): void => {
        if (searchVisible) dispatch({ type: SHOW_SEARCH });
        else dispatch({ type: HIDE_SEARCH });
    }, [searchVisible, dispatch]);

    useEffect((): void => {
        if (searchVisible) closeHandler();
    }, [searchVisible, closeHandler]);

    useOnClickOutside(ref, () => handleClickOutside());

    let logoSolidIcon: string;
    let logoDefaultIcon: string;

    switch (useBrand()) {
        case "spmortgage":
            switch (breakpoint) {
                case "XL":
                    logoSolidIcon = `mortgageSolid56`;
                    logoDefaultIcon = `mortgageDefault56`;
                    break;
                case "L":
                case "M":
                    logoSolidIcon = `mortgageSolid42`;
                    logoDefaultIcon = `mortgageDefault42`;
                    break;
                default:
                    logoSolidIcon = `logoSmallSolid42`;
                    logoDefaultIcon = `logoSmallDefault42`;
            }
            break;
        case "soderbergpartnersgroup":
            switch (breakpoint) {
                case "XL":
                    logoSolidIcon = `spgroupSolid56`;
                    logoDefaultIcon = `spgroupDefault56`;
                    break;
                default:
                    logoSolidIcon = `spgroupSolid42`;
                    logoDefaultIcon = `spgroupDefault42`;
            }
            break;
        case "sphab":
            switch (breakpoint) {
                case "XL":
                    logoSolidIcon = `sphabSolid56`;
                    logoDefaultIcon = `sphabDefault56`;
                    break;
                default:
                    logoSolidIcon = `sphabSolid42`;
                    logoDefaultIcon = `sphabDefault42`;
            }
            break;
        case "spuk":
            switch (breakpoint) {
                case "XL":
                    logoSolidIcon = `spukSolid56`;
                    logoDefaultIcon = `spukDefault56`;
                    break;
                case "L":
                case "M":
                    logoSolidIcon = `spukSolid42`;
                    logoDefaultIcon = `spukDefault42`;
                    break;
                default:
                    logoSolidIcon = `logoSmallSolid42`;
                    logoDefaultIcon = `logoSmallDefault42`;
            }
            break;
        case "claimslink":
            switch (breakpoint) {
                case "XL":
                    logoSolidIcon = `claimslinkSolid42`;
                    logoDefaultIcon = `claimslinkDefault42`;
                    break;
                case "L":
                case "M":
                    logoSolidIcon = `claimslinkSolid28`;
                    logoDefaultIcon = `claimslinkDefault28`;
                    break;
                default:
                    logoSolidIcon = `claimslinkSmallSolid28`;
                    logoDefaultIcon = `claimslinkSmallDefault28`;
            }
            break;
        default:
            switch (breakpoint) {
                case "XL":
                    logoSolidIcon = `logoSolid56`;
                    logoDefaultIcon = `logoDefault56`;
                    break;
                case "L":
                case "M":
                    logoSolidIcon = `logoSolid42`;
                    logoDefaultIcon = `logoDefault42`;
                    break;
                default:
                    logoSolidIcon = `logoSmallSolid42`;
                    logoDefaultIcon = `logoSmallDefault42`;
            }
    }

    const logoIcon =
        headerActive || theme === "lightgray" ? logoDefaultIcon : logoSolidIcon;

    const handleSearchClick = useCallback(() => toggleSearch(), [toggleSearch]);

    const handleSearchKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLButtonElement>): void => {
            if (event?.key === "Enter") {
                event.preventDefault();
                toggleSearch();
            }
        },
        [toggleSearch],
    );

    return (
        <ThemeProvider
            theme={{
                theme: headerActive ? "lightgray" : theme,
                contentTheme: contentTheme,
            }}
        >
            <Wrapper
                role="banner"
                onMouseEnter={() => enterHandler()}
                onMouseLeave={() => leaveHandler()}
                onTouchEnd={() => leaveHandler()}
                ref={ref}
                $showMenu={visible}
                $menuOpen={menuOpen}
                $headerActive={headerActive}
                $searchVisible={searchVisible}
            >
                <Container>
                    <Inner>
                        <SkipLinkWrapper>
                            <SkipLink to="#maincontent">
                                {l18n?.skipToContent || "Skip to main content"}
                            </SkipLink>
                        </SkipLinkWrapper>
                        <Logo
                            startPageUrl={startPageUrl}
                            logoTitle={logoTitle}
                            logoIcon={logoIcon}
                        />
                        {!searchVisible && (
                            <Navigation
                                label="Top Navigation"
                                navigation={navigation}
                                activeNav={activeNav}
                                setActiveNav={setActiveNavHandler}
                                navigateHandler={navigateHandler}
                                data-cy={getTestId("header-navigation")}
                                theme={
                                    headerActive
                                        ? "lightgray"
                                        : (contentTheme as
                                              | "lightgray"
                                              | "blue"
                                              | "cyan"
                                              | "coral"
                                              | "black"
                                              | "white"
                                              | undefined)
                                }
                            />
                        )}
                        {searchPageUrl && (
                            <SearchButton
                                icon={searchVisible ? "close28" : "search28"}
                                actionTheme={
                                    headerActive ? "black" : contentTheme
                                }
                                size="medium"
                                iconOnly={true}
                                a11y={true}
                                onClick={handleSearchClick}
                                onKeyDown={handleSearchKeyDown}
                                data-cy={getTestId("search-button")}
                            >
                                {l18n?.searchLabel}
                            </SearchButton>
                        )}
                        {sites && sites.length > 0 && (
                            <LanguagePicker sites={sites} inHeader />
                        )}
                        {loginPageText && loginPageUrl && (
                            <Button
                                icon="login28"
                                url={loginPageUrl}
                                iconOnly={breakpoint === "S" ? true : false}
                                a11y={breakpoint === "S" ? true : false}
                                actionTheme={
                                    headerActive ? "black" : contentTheme
                                }
                                size="medium"
                                title={loginPageTitle}
                                data-cy={getTestId("login-button")}
                            >
                                {loginPageText}
                            </Button>
                        )}
                        <MenuButton
                            icon={menuOpen ? "close28" : "menu28"}
                            actionTheme={headerActive ? "black" : contentTheme}
                            size="medium"
                            iconOnly={true}
                            a11y={true}
                            onClick={useCallback(
                                () => toggleMenu(mobileMenuNavName),
                                [toggleMenu],
                            )}
                            onKeyDown={useCallback(
                                (
                                    event: React.KeyboardEvent<HTMLButtonElement>,
                                ) =>
                                    handleMenuBtnKeyDown(
                                        event,
                                        mobileMenuNavName,
                                    ),
                                [handleMenuBtnKeyDown],
                            )}
                            data-cy={getTestId("menu-buttom")}
                        >
                            {l18n?.menu}
                        </MenuButton>
                    </Inner>
                </Container>
                <NavigationMega
                    label="Mega Navigation"
                    navigation={navigation}
                    mainLinks={mainLinks}
                    open={menuOpen}
                    closeHandler={closeHandler}
                    navigateHandler={navigateHandler}
                    setActiveNav={toggleNavHandler}
                    activeNav={activeNav}
                    loginPageText={loginPageText}
                    loginPageUrl={loginPageUrl}
                    data-cy={getTestId("mega-navigation")}
                    l18n={{
                        expand: l18n?.expand,
                    }}
                />
            </Wrapper>
            {searchPageUrl && (
                <SearchOverlay
                    searchPageUrl={searchPageUrl}
                    l18n={{
                        searchPlaceholder: l18n?.searchPlaceholder,
                        searchLabel: l18n?.searchLabel,
                    }}
                />
            )}
        </ThemeProvider>
    );
};

export default React.memo(Header, isEqual);
