import React, { ReactElement, useCallback } from "react";
import isEqual from "react-fast-compare";
import { ThemeProvider } from "styled-components";

import Icon from "components/Icon";
import NavigationMegaItems from "components/NavigationMegaItems";
import { appendComponentWithNoBr } from "utils/appendComponentWithNoBr";
import { convertToValidID } from "utils/convertToValidID";

import {
    Wrapper,
    Container,
    TopNav,
    MainLinks,
    MainLink,
    LoginButton,
    SubNavContainer,
    SubNavWrapper,
    Description,
    Title,
    Introduction,
    Link,
    LinkButton,
    BackButton,
} from "./NavigationMega.styled";
import NavigationMegaProps, {
    NavigationMegaMainLinkProps,
    NavigationMegaSubProps,
} from "./NavigationMegaProps";

const TopNavItem = React.memo(
    ({
        navId,
        item,
        open,
        subNavId,
        onClick,
        onKeyDown,
        navigateHandler,
    }: NavigationMegaSubProps): ReactElement => {
        const handleNavigate = useCallback(
            (_) => {
                if (navigateHandler) {
                    navigateHandler(
                        1, //level
                        item.displayName,
                    );
                }
            },
            [item.displayName, navigateHandler],
        );

        return (
            <li key={navId}>
                {item.items && item.items.length > 0 ? (
                    <LinkButton
                        title={item.displayName}
                        onClick={(event) => onClick(event, navId)}
                        onKeyDown={(event) => onKeyDown(event, navId)}
                        aria-controls={`${subNavId}-nav`}
                        className={open ? "active" : ""}
                    >
                        <span>{item.displayName}</span>
                        <Icon icon="bigArrowRight28" />
                    </LinkButton>
                ) : (
                    <Link
                        to={item.url}
                        exact
                        activeClassName="active"
                        title={item.displayName}
                        onClick={handleNavigate}
                    >
                        <span>{item.displayName}</span>
                    </Link>
                )}
            </li>
        );
    },
);
TopNavItem.displayName = "TopNavItem";

const SubNav = React.memo(
    ({
        subNavId,
        navId,
        open,
        index,
        item,
        l18n,
        navigateHandler,
        onClick,
        onKeyDown,
    }: NavigationMegaSubProps): ReactElement => {
        const handleNavigate = useCallback(() => {
            if (navigateHandler) {
                navigateHandler(1, item.displayName);
            }
        }, [item.displayName, navigateHandler]);

        return (
            <SubNavWrapper
                id={`${subNavId}-nav`}
                key={navId}
                aria-labelledby={`${subNavId}-header`}
                $open={open}
            >
                <BackButton
                    actionTheme="black"
                    title={item.displayName}
                    aria-controls={`${subNavId}-nav`}
                    icon="bigArrowLeft28"
                    iconOnly={true}
                    a11y={true}
                    onClick={(event) => onClick(event, `nav${index}`)}
                    onKeyDown={(event) => onKeyDown(event, `nav${index}`)}
                >
                    Back
                </BackButton>
                <Description id={`${subNavId}-header`}>
                    {item.displayName && (
                        <Title
                            to={item.url}
                            onClick={handleNavigate}
                            id={`${subNavId}-heading`}
                        >
                            {appendComponentWithNoBr(
                                item.displayName,
                                <Icon
                                    icon="longArrowRight35"
                                    aria-hidden="true"
                                />,
                            )}
                        </Title>
                    )}
                    {item.introduction && (
                        <Introduction>{item.introduction}</Introduction>
                    )}
                </Description>
                <SubNavContainer>
                    <NavigationMegaItems
                        items={item.items}
                        id={subNavId}
                        navigateHandler={navigateHandler}
                        open={open}
                        highlightedNavigation={item.highlightedNavigation}
                        l18n={l18n}
                    />
                </SubNavContainer>
            </SubNavWrapper>
        );
    },
    isEqual,
);
SubNav.displayName = "SubNav";

const MainLinkItem = React.memo(
    ({ item, navigateHandler }: NavigationMegaMainLinkProps): ReactElement => {
        const handleNavigate = useCallback(() => {
            if (navigateHandler) {
                navigateHandler(2, item.displayName);
            }
        }, [item.displayName, navigateHandler]);

        return (
            <li>
                <MainLink
                    to={item.url}
                    exact
                    hoverSpan={true}
                    isNavigation={true}
                    activeClassName="active"
                    title={item.displayName}
                    onClick={handleNavigate}
                >
                    <span>{item.displayName}</span>
                </MainLink>
            </li>
        );
    },
);
MainLinkItem.displayName = "MainLinkItem";

const NavigationMega = ({
    setActiveNav,
    activeNav,
    navigation,
    mainLinks,
    loginPageUrl,
    loginPageText,
    label,
    open,
    l18n,
    navigateHandler,
}: NavigationMegaProps): ReactElement => {
    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLButtonElement>, nav: string): void => {
            if (event?.key === "Enter") {
                event.preventDefault();
                setActiveNav(nav);
            }
        },
        [setActiveNav],
    );

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>, nav: string): void => {
            event.preventDefault();
            setActiveNav(nav);
        },
        [setActiveNav],
    );

    return (
        <ThemeProvider theme={{ theme: "lightgray" }}>
            <Wrapper $open={open} $allClosed={!activeNav}>
                <Container as="nav" aria-label={label}>
                    {/* Mobile/tablet */}
                    <TopNav $allClosed={!activeNav}>
                        {navigation?.items?.map((item, index) => {
                            const navId = `nav${index}`;
                            const open = activeNav == `nav${index}`;
                            const subNavId = convertToValidID(
                                item.displayName || navId,
                            );

                            return (
                                <TopNavItem
                                    key={`TopNavItem-${index}`}
                                    item={item}
                                    open={open}
                                    navId={navId}
                                    subNavId={subNavId}
                                    navigateHandler={navigateHandler}
                                    onClick={handleClick}
                                    onKeyDown={handleKeyDown}
                                />
                            );
                        })}
                    </TopNav>
                    <MainLinks $show={!activeNav}>
                        {mainLinks?.items?.map((item, index) => (
                            <MainLinkItem
                                key={`MainLinkItem-${index}`}
                                item={item}
                            />
                        ))}
                    </MainLinks>
                    {loginPageText && loginPageUrl && (
                        <LoginButton
                            icon="login28"
                            url={loginPageUrl}
                            actionTheme="black"
                            size="medium"
                            $show={!activeNav}
                        >
                            {loginPageText}
                        </LoginButton>
                    )}
                    {/* All devices */}
                    {navigation?.items?.map((item, index) => {
                        if (item.items && item.items.length > 0) {
                            const navId = `subnav${index}`;
                            const open = activeNav == `nav${index}`;
                            const subNavId = convertToValidID(
                                `${item.displayName}`,
                            );

                            return (
                                <SubNav
                                    key={`SubNavItem-${index}`}
                                    item={item}
                                    navId={navId}
                                    open={open}
                                    index={index}
                                    subNavId={subNavId}
                                    navigateHandler={navigateHandler}
                                    onClick={handleClick}
                                    onKeyDown={handleKeyDown}
                                    l18n={l18n}
                                />
                            );
                        }
                    })}
                </Container>
            </Wrapper>
        </ThemeProvider>
    );
};

export default React.memo(NavigationMega);
